<template>
  <div class="qr-code"></div>
</template>

<script>
import QRCode from 'qrcode';

export default {
  props: {
    value: {
      type: String,
    },
    margin: {
      type: Number,
      default: 1,
    },
    width: {
      type: Number,
    },
    height: {
      type: Number,
    },
  },
  data() {
    return {
      content: '',
    };
  },
  mounted() {
    this.render();
  },
  watch: {
    value() {
      this.render();
    },
  },
  computed: {
    options() {
      const options = {
        type: 'svg',
        margin: this.margin,
      };

      if (this.width) {
        options.width = this.width;
      }
      if (this.height) {
        options.height = this.height;
      }

      return options;
    },
  },
  methods: {
    async render() {
      this.$el.innerHTML = await QRCode.toString(this.value, this.options);
    },
  },
};
</script>
