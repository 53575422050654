import { UTILITY, MARKETING } from '@/data/message-template-categories';

export const APPOINTMENT_CREATION = 'appointment_creation';
export const APPOINTMENT_CONFIRMATION = 'appointment_confirmation';
export const APPOINTMENT_CONFIRMATION_GROUPED = 'appointment_confirmation_grouped';
export const APPOINTMENT_REMINDER = 'appointment_reminder';
export const APPOINTMENT_REMINDER_GROUPED = 'appointment_reminder_grouped';
export const BIRTHDAY = 'birthday';
export const SATISFACTION_SURVEY = 'satisfaction_survey';
export const CUSTOM = 'custom';
export const INBOUND = 'inbound';
export const FREE_TEXT = 'free_text';

export const types = {
  [APPOINTMENT_CREATION]: 'Agendamento criado',
  [APPOINTMENT_CONFIRMATION]: 'Confirmação de agendamento',
  [APPOINTMENT_CONFIRMATION_GROUPED]: 'Confirmação de agendamento - Agrupado',
  [APPOINTMENT_REMINDER]: 'Lembrete de agendamento',
  [APPOINTMENT_REMINDER_GROUPED]: 'Lembrete de agendamento - Agrupado',
  // [BIRTHDAY]: 'Aniversário',
  // [SATISFACTION_SURVEY]: 'Pesquisa de satisfação',
  // [CUSTOM]: 'Customizado',
  [INBOUND]: 'Mensagem recebida',
  [FREE_TEXT]: 'Genérica',
};

export const templateCategoryMapping = {
  [APPOINTMENT_CREATION]: UTILITY,
  [APPOINTMENT_CONFIRMATION]: UTILITY,
  [APPOINTMENT_CONFIRMATION_GROUPED]: UTILITY,
  [APPOINTMENT_REMINDER]: UTILITY,
  [APPOINTMENT_REMINDER_GROUPED]: UTILITY,
  [BIRTHDAY]: MARKETING,
  [SATISFACTION_SURVEY]: MARKETING,
  [CUSTOM]: MARKETING,
};

export function getName(key) {
  return key in types ? types[key] : key;
}

export default types;
