export const UTILITY = 'utility';
export const MARKETING = 'marketing';
export const AUTHENTICATION = 'authentication';

export const categories = {
  [UTILITY]: 'Utilidade',
  [MARKETING]: 'Marketing',
  // [AUTHENTICATION]: 'Autenticação',
};

export function getName(key) {
  return key in categories ? categories[key] : key;
}

export default categories;
