<template>
  <div class="setting-item">
    <div class="setting-item-content">
      <div class="setting-item-name">
        <span>{{ name }}</span>
        <small v-if="soon" class="ml-1 text-secondary">(Em breve)</small>
      </div>
      <div class="setting-item-description" v-if="description">{{ description }}</div>
      <div class="setting-item-info" v-if="info">{{ info }}</div>
    </div>
    <div class="setting-item-action">
      <button
        v-if="actionIcon"
        class="btn btn-action btn-neutral btn-icon mr-2"
        @click="actionFn"
        :disabled="isActionDisabled"
      ><fa-icon :icon="actionIcon"></fa-icon></button>
      <slot>
        <label
          v-if="type === 'switch'"
          class="form-switch">
          <input
            type="checkbox"
            v-model="actionInput"
            :disabled="isDisabled">
          <i class="form-icon"></i>
        </label>
        <select
          v-else-if="type === 'select'"
          class="form-select"
          v-model="actionInput"
          :disabled="isDisabled"
        >
          <option
            v-for="{ value, text } in options"
            :value="value"
            :key="value"
          >{{ text }}</option>
        </select>
        <dx-input-number
          v-else-if="type === 'number'"
          type="text"
          class="form-input"
          v-model="actionInput"
          :disabled="isDisabled"
        ></dx-input-number>
        <input
          v-else
          class="form-input"
          v-model="actionInput"
          :disabled="isDisabled"
        >
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Boolean, String, Number],
    },
    name: {
      type: String,
    },
    description: {
      type: String,
    },
    info: {
      type: String,
    },
    type: {
      type: String,
    },
    options: {
      type: Array,
      default: () => [],
    },
    actionIcon: {
      type: Array,
    },
    actionFn: {
      type: Function,
      default: () => {},
    },
    actionDisabled: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    soon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    isDisabled() {
      return this.soon || this.disabled;
    },
    isActionDisabled() {
      return this.soon || this.actionDisabled;
    },
    actionInput: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
        if (this.value !== value) {
          this.$emit('change', value);
        }
      },
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";

.setting-item {
  align-items: center;
  border-top: $border-width solid $border-color;
  display: flex;
  padding: $layout-spacing 0;
  &.first-item {
    border-top: 0;
  }
  .setting-item-content {
    flex: 1 1 auto;
    margin-right: $layout-spacing-lg;
  }
  .setting-item-name {
    font-weight: 400;
  }
  .setting-item-description {
    color: $gray-color-700;
    font-size: $font-size-sm;
    padding-top: $border-width-lg;
  }
  .setting-item-info {
    color: $info-color;
    font-size: $font-size-sm;
    padding-top: $border-width-lg;
  }
  .setting-item-action {
    align-items: center;
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
    text-align: right;
    .btn {
      transition: all .15s ease;
    }
    .btn-icon svg {
      color: $gray-color-600;
    }
    .form-select {
      width: auto;
      max-width: 10rem;
    }
    .form-switch {
      margin: 0;
      padding-left: $control-size-sm;
    }
  }
}
</style>
