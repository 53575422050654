export const WHATSAPP_APPOINTMENT_CREATION = `[patient_name],
Agendamento realizado para *[date_hour]* com o(a) profissional *[professional_name]*!

[facility_name]
[facility_address]`;

export const WHATSAPP_APPOINTMENT_CONFIRMATION = `[patient_name],
Você tem atendimento *[date_hour]* com o(a) profissional *[professional_name]*.

Clique no link a seguir para confirmar sua presença:
[appointment_link]

[facility_name]
[facility_address]`;

export const WHATSAPP_WEB_APPOINTMENT_CONFIRMATION = `[patient_name],
Você tem atendimento *[date_hour]* com o(a) profissional *[professional_name]*.
Podemos confirmar sua presença?

[facility_name]
[facility_address]`;

export const WHATSAPP_APPOINTMENT_REMINDER = `[patient_name],
Apenas para lembrá-lo de seu atendimento *[date_hour]* com o(a) profissional *[professional_name]*.
Recomendamos que chegue com 10 minutos de antecedência.

[facility_name]
[facility_address]`;
