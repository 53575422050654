<template>
  <div class="whatsapp-options">
    <div class="loading loading-lg" v-if="loading"></div>
    <template v-else>
      <div class="columns">
        <div class="column col-7 form-group">
          <small>Tipo de integração</small>
          <div
            class="text-bold text-secondary"
          >Número dedicado</div>
        </div>
        <div class="column col-5 form-group">
          <small>Status</small>
          <div
            class="text-bold"
            :class="isConnected ? 'text-success' : 'text-error'"
          >{{ status }}</div>
        </div>
      </div>
      <template v-if="isDedicated && isConnected">
        <div class="columns">
          <div class="column col-7 form-group">
            <small>Conta conectada</small>
            <div
              class="text-bold text-secondary"
            >{{ info.number | phone }} - {{ info.name }}</div>
          </div>
          <div class="column col-5 form-group">
            <small>Webhook</small>
            <div
              class="text-bold text-secondary"
            >{{ info.webhook ? 'Ativado' : 'Desativado' }}</div>
          </div>
        </div>
        <div class="mt-2">
          <button
            class="btn btn-error btn-icon btn-icon-left"
            :class="{ loading: disconnecting }"
            @click="disconnect"
          ><fa-icon :icon="['fal', 'sign-out-alt']"></fa-icon> Desconectar conta</button>
          <button
            class="btn btn-icon btn-icon-left ml-2"
            :class="{ loading: configuring, 'btn-gray': info.webhook, 'btn-info': !info.webhook }"
            @click="toggleWebhook"
          ><fa-icon
            :icon="['fal', info.webhook ? 'times-circle' : 'check-circle']"
          ></fa-icon> {{ info.webhook ? 'Desativar' : 'Ativar' }} webhook</button>
        </div>
      </template>
      <template v-else-if="isDedicated">
        <div v-if="info.qrCode" class="qr-code-block mt-2">
          <div class="instructions">
            <p class="text-bold">
              Para enviar e receber mensagens, é necessário autorizar a conexão em seu aparelho.
            </p>
            <p class="mb-1">- Abra o WhatsApp em seu telefone</p>
            <p class="mb-1">- Acesse a área de configurações do WhatsApp</p>
            <p class="mb-1">- Toque em "Aparelhos conectados" depois em "Conectar um aparelho"</p>
            <p class="mb-1">- Aponte seu telefone para o código ao lado</p>
            <p class="mt-2 mb-0 text-info">O código QR é válido por 45 segundos.</p>
          </div>
          <qr-code :value="info.qrCode" :margin="0"></qr-code>
        </div>
        <div v-else class="mt-2">
          <button class="btn btn-primary" @click="load">Tentar novamente</button>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import QrCode from '@/components/image/QrCode.vue';
import { mergeFrom } from '@/helpers/object';

const MAX_TRIES = 2;

export default {
  props: {
    params: {
      type: Object,
    },
  },
  components: {
    QrCode,
  },
  data() {
    return {
      loading: true,
      disconnecting: false,
      configuring: false,
      info: this.blankInfo(),
      tries: MAX_TRIES,
    };
  },
  mounted() {
    this.load();
  },
  computed: {
    isDedicated() {
      return this.params.dedicated;
    },
    isConnected() {
      return this.info.status === 'connected';
    },
    status() {
      return this.isConnected ? 'Conectado' : 'Desconectado';
    },
  },
  methods: {
    async load() {
      this.loading = true;
      if (this.isDedicated) {
        try {
          const { data } = await this.$http.get('/facility-settings/whatsapp');
          this.info = mergeFrom(this.blankInfo(), data);
          this.postLoad();
        } catch (e) {
          this.$toast.error(e);
        }
      }
      this.loading = false;
    },
    postLoad() {
      if (this.info.qrCode) {
        if (this.tries > 0) {
          this.tries -= 1;
          setTimeout(this.load, 45000);
        } else {
          this.info.qrCode = null;
          this.tries = 1;
        }
      }
    },
    async disconnect() {
      this.disconnecting = true;
      try {
        await this.$http.post('/facility-settings/whatsapp/disconnect');
        this.info.status = 'disconnected';
        await this.load();
      } catch (e) {
        this.$toast.error(e);
      }
      this.disconnecting = false;
    },
    async toggleWebhook() {
      this.configuring = true;
      try {
        const postData = {
          enabled: !this.info.webhook,
        };
        await this.$http.post('/facility-settings/whatsapp/webhook', postData);
        this.info.webhook = postData.enabled;
      } catch (e) {
        this.$toast.error(e);
      }
      this.configuring = false;
    },
    blankInfo() {
      return {
        status: 'connected',
        qrCode: null,
        number: '',
        name: '',
        webhook: false,
      };
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";

.whatsapp-options {
  .qr-code-block {
    display: flex;
    .instructions {
      flex: 1;
      margin-right: $layout-spacing-lg;
      padding-top: $layout-spacing-sm;
    }
    .qr-code {
      border: $border-width solid $border-color-dark;
      border-radius: $border-radius;
      flex: 0 0 12rem;
      padding: $layout-spacing;
      svg {
        display: block;
      }
    }
  }
}
</style>
